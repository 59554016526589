<template>
  <div class="product-box">
    <img style="width: 100%;height: 90%;" alt="Vue logo" :src="data.url">
    <p>{{ data.title }}</p>
    <div class="product-box-txt">
      <div class="product-box-tip">智能新品</div>
      <div class="product-box-tip">弹窗广告</div>
      <div class="product-box-buttom" @click="gotoDetail(data)">查看</div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: 'ProductBox',
  props: {
    data: {
      required: true
    }
  },
  methods: {
    goNew(val) {
      if (this.$route.path !== val) {
        this.$router.push(val);
      }
    },
    gotoDetail() {
      const token = localStorage.getItem('token'); // 从本地存储中获取 token
      if (!token) {
        this.$confirm(
          '暂未登录，请先前往登录',
          "系统提示",
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: "warning",
            customClass: 'my-confirm'  //使提示文字颜色变红
          }
        )
          .then(() => {
            // 用户点击了确定按钮
            this.goNew('/login')
          })
          .catch(() => {
            // 用户点击了取消按钮
            console.log('取消')
          })
      } else {
        // 跳转到命名路由
        this.$router.push({ path: '/detail', query: { title: this.data.title } });
      }

    }
  }
}
</script>
    
<style scoped>
.product-box {
  display: inline-block;
  border-radius: 5px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  padding: 2%;
  box-sizing: border-box;
  /* 让 padding 和 border 包含在宽度内 */
}

.product-box p {
  font-size: 1em;
  font-weight: bold;
  color: #2A2A2A;
}

.product-box-txt {
  position: relative;
  margin: 35px 5px 0 0;
}

.product-box-tip {
  display: inline-block;
  padding: 1% 2%;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #83B24C;
  font-size: 12px;
  text-align: center;
  color: #83B24C;
  margin-right: 5px;
  margin-bottom: 4%;
}

.product-box-buttom {
  width: 25%;
  min-width: 60px;
  height: 32px;
  line-height: 32px;
  border-radius: 16px;
  text-align: center;
  background: #83B24C;
  cursor: pointer;
  font-size: 14px;
  color: #FFFFFF;
  display: inline-block;
  position: absolute;
  right: 0;
}
</style>
      