<template>
  <div>
    <img style="width: 100%;" alt="Vue logo" src="../assets/banner.png">
    <div class="main-box">
      <img class="main-box-circleA" alt="circle" src="../assets/circleB.png">
      <img class="main-box-circleB" alt="circle" src="../assets/circleA.png">
      <div class="maxin-box-back">
        <div class="maxin-box-back-divider">
          <el-divider>公司介绍</el-divider>
        </div>
        <div class="maxin-box-back-row">
          <el-row :gutter="40">
            <el-col :span="12">
              <img style="width: 100%;height: 100%;" alt="Vue logo" src="../assets/image.png">
            </el-col>
            <el-col :span="12">
              <div style="display: flex;flex-direction: column;width: 100%;height: 100%;">
                <p v-for="(item, index) in introduce" :key="index" style="text-indent: 2em;flex: 1;margin: 1% 0;">{{ item }}</p>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-divider></el-divider>

        <!-- <div class="maxin-box-back-row"> -->
        <el-row :gutter="80">
          <el-col :span="16">
            <div style="width: 100%;height: 100%;">
              <img style="width: 100%;height: 346px;background: #F7F7F7;padding: 20px;box-sizing: border-box;"
                alt="Vue logo" src="../assets/home1.png">
            </div>
          </el-col>
          <el-col :span="8">
            <p class="title-text" style="height: 133px;display: flex;align-items: flex-end;">流量大</p>
            <p class="title-text" style="text-align: center;">分布广</p>
          </el-col>
        </el-row>
        <!-- </div> -->
        <el-divider></el-divider>

        <div class="maxin-box-back-row">
          <div style="width: 40%;">
            <div style="display: flex;flex-direction: column; padding: 10%;box-sizing: border-box;">
              <p class="title-text" style="margin-top: 0px">用户画像</p>
              <img style="width: 100%;height: 100%;" alt="Vue logo" src="../assets/home2.png">
            </div>
          </div>
          <div style="width: 60%;">
            <div style="width: 100%;height: 100%;background: #F7F7F7;padding: 20px;box-sizing: border-box;
              display: flex;align-items: center;box-sizing: border-box;">
              <img style="width: 65%;height: 100%;margin-right: 5%;" alt="Vue logo" src="../assets/home3.png">
              <div style="width: 30%;height: 100%;display: inline-block;">
                <p style="font-size: 24px;font-weight: 900;color: #ECB332;">年轻</p>
                <p v-for="(item, index) in introduce2" :key="index">
                  <span style="border-radius: 2px;background: #83B24C;color: #83B24C;">|</span>
                  <span style="margin-left: 4px;font-size: 18px;font-weight: 500;color: #83B24C;">{{ item }}</span>
                </p>
              </div>

            </div>
          </div>
        </div>
        <el-divider></el-divider>

        <div class="maxin-box-back-row">
          <div style="width: 55%;">
            <div
              style="width: 100%;height: 100%;background: #F7F7F7;padding: 40px;box-sizing: border-box;box-sizing: border-box;position: relative;">
              <img style="width: 100%;height: 100%;margin-right: 5%;" alt="Vue logo" src="../assets/home4.png">
              <span class="title-text" style="position: absolute;top: 16%;right: 8%;">精准传播</span>
            </div>
          </div>
          <div style="width: 43%;margin-left: 2%;">
            <div style="display: flex;flex-direction: column;box-sizing: border-box;">
              <img style="width: 100%;height: 100%;" alt="Vue logo" src="../assets/home5.png">
              <p style="font-size: 12px;font-weight: 500;color: #9E9E9E;text-align: center;">电桩分布情况</p>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="main-box">
      <div class="maxin-box-back-divider">
        <el-divider>产品列表</el-divider>
        <ProductBox v-for="(item, index) in productList" :key="index" :data="item" style="width: 31%;margin: 1%;">
        </ProductBox>
      </div>
    </div>
  </div>
</template>
  
<script>
import ProductBox from '../components/ProductBox.vue'

export default {
  name: 'HomePage',
  components: {
    ProductBox
  },
  data() {
    return {
      introduce: [
        '嘉兴智行物联网技术有限公司（以下简称“闪开”）专注于消费能源物联网平台、能源资产运营SaaS平台和标准化运营服务作业（SOP）网络的研发、设计、运营以及设备的生产制造，其能源资产包括低速电动车充电桩、电动汽车充电桩、动力和储能电池、工商业光储充系统等，是一家以能源互联网综合解决方案和充电平台运营能力为基础，构建多场景光伏发电、储能削峰填谷、电动车充换电服务和动力电池销售租赁的能源物联网科技公司。',
        '目前，公司旗下已拥有闪开·来电，闪开·iFaster，闪开·智守，闪开·新能源和闪开·光合五大品牌系列产品。闪开注重技术研发，知识产权保护，累计获得发明专利及专利技术80+，并已通过ISO9001，ISO27001，ISO20000等管理体系，为国家高新技术企业和浙江科技型企业，同时也是腾讯生态、联想生态及中移动的密切合作伙伴。',
        '闪开在产业链中的出色表现，得到了资本市场的广泛关注，公司先后获得过易津资本、盛弘股份（300693）、雅迪控股（01585）、中来股份（300393）、天赐材料（002709）等战略投资，为长远发展注入新的活力。',
        '嘉兴智行物联网技术有限公司依托强大的研发与运营能力，已在全国300多个城市完成能源物联网产业布局，并在全国设立五个省级运营公司及新疆生产物流中心，是永升物业等物业集团的战略合作伙伴，为深耕全国市场打下了坚实的基础。'
      ],
      introduce2: [
        '男女用户数量相当，男性偏多',
        '青壮年为主，集中在18-45岁之间'
      ],
      productList: [
        { id: '1', title: '充电页弹窗广告', url: require('../assets/homeitemM.jpg') },
        { id: '2', title: '充电页banner横幅', url: require('../assets/homeitemM.jpg') },
        { id: '3', title: '充值页广告', url: require('../assets/homeitemD.png') },
        { id: '4', title: '福利中心广告', url: require('../assets/homeitemF.png') },
        { id: '5', title: '充电消息广告', url: require('../assets/homeitemG.png') },
        { id: '6', title: '微信推文广告', url: require('../assets/homeitemH.png') },
        { id: '7', title: '微信跳转广告', url: require('../assets/homeitemK.png') },
        { id: '8', title: '小程序跳转广告', url: require('../assets/homeitemA.png') },
        { id: '9', title: '支付宝跳转广告', url: require('../assets/homeitemE.png') },
      ]
    }
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  },
  methods: {
  }
}
</script>
  
<style  scoped>
.main-box {
  position: relative;
  width: 82%;
  min-height: 400px;
  margin: 25px auto;
  padding: 20px 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  /* 隐藏超出 div 的部分 */
}

.maxin-box-back-divider .el-divider--horizontal {
  width: 20em;
  margin: 19px auto 40px;
}

.maxin-box-back-divider .el-divider--horizontal .el-divider__text {
  font-weight: 500;
  color: #303133;
  font-size: 1.5em;
  font-weight: bold;
}

.main-box-circleA {
  position: absolute;
  left: -4%;
  top: -3%;
  width: 15em;
  height: 15em;
}

.main-box-circleB {
  position: absolute;
  right: -8%;
  bottom: -6%;
  width: 15em;
  height: 15em;
}

.maxin-box-back {
  width: 96%;
  min-height: 360px;
  margin: 0 auto;
  padding: 1em 15px;
  border-radius: 23px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.maxin-box-back-row {
  display: flex;
}

.title-text {
  font-size: 2em;
  font-weight: 900;
  color: #83B24C;
}

.maxin-box-back-row .el-row {
  display: flex;
  flex-wrap: wrap;
}

.maxin-box-back-row .el-col {
  display: flex;
  align-items: center;
  min-height: 100%;
}
</style>
  